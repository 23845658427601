import referralTypes from "../../actionTypes/referAndEarnTypes";

const initialState = {
  fetchreferral: false,
  referralDataList: null,
  pagination: { pageNo: 1, pages: 1 },
  setRefereeDetails: {},
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case referralTypes.FETCH_REFERRAL_DATA: {
      return { ...state, fetchreferral: action.payload };
    }
    case referralTypes.SET_REFERRAL_DATA: {
      return { ...state, referralDataList: action.payload };
    }
    case referralTypes.FETCH_REFEREE_DETAILS: {
      return { ...state, setRefereeDetails: action.payload };
    }
    case referralTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
