import React from "react";
import * as Icon from "react-feather";
import { routes } from "../component_route/Index";
import { store } from "../redux/storeConfig/store";
import permissionsObj from "../utills/adminPermissions";
import filterNavigationConfig from "../utills/filterNavigationConfig";

const { country } = store.getState().auth;

const navigationConfig = [
  {
    id: "dashboard",
    title: "Dashboard",
    type: "item",
    icon: <Icon.PieChart size={20} />,
    permissions: ["admin", "sub-admin"],
    navLink: routes.dashboard,
    module: permissionsObj.dashboard,
  },
  {
    id: "userManagement",
    title: "Users",
    type: "collapse",
    icon: <Icon.User size={20} />,
    permissions: ["admin", "sub-admin"],
    modules: permissionsObj.manageUsers,
    children: [
      {
        id: "customers",
        title: "Customer Profile",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.customers,
      },
      {
        id: "customer audit log",
        title: "Customer Audit Log",
        type: "item",
        icon: <Icon.Users size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.customerAuditLog,
      },
      {
        id: "kycManagement",
        title: "Manage KYC",
        type: "item",
        icon: <Icon.Award size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.kyc,
      },
      {
        id: "managePayee",
        title: "Manage Payees",
        type: "item",
        icon: <Icon.UserPlus size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.payee,
        subModule: permissionsObj.manageUsers[2],
      },
      {
        id: "panManagement",
        title: "Manage PAN",
        type: "item",
        icon: <Icon.Book size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.pan,
      },
      {
        id: "walletbalance",
        title: "Wallet Balance",
        type: "item",
        icon: <Icon.Watch size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.walletbalance,
      },
      {
        id: "notification",
        title: "Notification",
        type: "item",
        icon: <Icon.Bell size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.notification,
      },
    ],
  },
  {
    id: "transactionManagement",
    title: "Transactions",
    type: "collapse",
    icon: <Icon.DollarSign size={20} />,
    permissions: ["admin", "sub-admin"],
    modules: permissionsObj.manageTransaction,
    children: [
      {
        id: "transactionHistory",
        title: "Transactions",
        type: "item",
        icon: <Icon.DollarSign size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.transactionHistory,
        subModules: permissionsObj.manageTransaction[0],
      },
      {
        id: "withdrawalLimit",
        title: "Edit Limits",
        type: "item",
        icon: <Icon.Package size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.withdrawal,
        subModule: permissionsObj.manageTransaction[1],
      },
    ],
  },
  {
    id: "nontransaction",
    title: "Non Transaction",
    type: "item",
    icon: <Icon.DollarSign size={20} />,
    navLink: routes.nonTransaction,
  },
  {
    id: "manageContent",
    title: "Manage Content",
    type: "collapse",
    icon: <Icon.FileText size={20} />,
    permissions: ["admin", "sub-admin"],
    modules: permissionsObj.manageContents,
    children: [
      {
        id: "manageBanner",
        title: "Manage Banner",
        type: "item",
        icon: <Icon.Image size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.banner,
        subModule: permissionsObj.manageContents[0],
      },
      {
        id: "manageUrgentAnnouncement",
        title: "Announcement",
        type: "item",
        icon: <Icon.Volume2 size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.urgentAnnouncement,
        subModule: permissionsObj.manageContents[1],
      },
      {
        id: "manageAppConfiguration",
        title: "Manage App",
        type: "item",
        icon: <Icon.Tool size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.appConfig,
        subModule: permissionsObj.manageContents[2],
      },
      {
        id: "manageTicket",
        title: "Manage Ticket",
        type: "item",
        icon: <Icon.Layers size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.ticket,
        subModule: permissionsObj.manageContents[3],
      },
      {
        id: "manageFeedback",
        title: "Feedbacks",
        type: "item",
        icon: <Icon.MessageSquare size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.feedback,
        subModule: permissionsObj.manageContents[4],
      },
      {
        id: "rejectReason",
        title: "Reject Reason",
        type: "item",
        icon: <Icon.Crosshair size={20} />,
        permissions: ["admin", "sub-admin"],
        navLink: routes.rejectReason,
        subModule: permissionsObj.manageContents[5],
      },
    ],
  },
  // {
  //   id: "complyAdvantage",
  //   title: "Comply Advantage",
  //   type: "item",
  //   icon: <Icon.Monitor size={20} />,
  //   permissions: ["admin", "sub-admin"],
  //   navLink: routes.complyAdvantage,
  // },

  {
    id: "manageAdmin",
    title: "Manage Admin",
    type: "item",
    icon: <Icon.User size={20} />,
    permissions: ["admin"],
    navLink: routes.manageAdmin,
  },
  {
    id: "auditLogs",
    title: "Audit Logs",
    type: "item",
    icon: <Icon.Activity size={20} />,
    permissions: ["admin"],
    navLink: routes.auditLogs,
  },
  {
    id: "managePaymentGateways",
    title: "Manage Payment Gateways",
    type: "item",
    icon: <Icon.CornerRightDown size={20} />,
    permissions: ["admin"],
    navLink: routes.manageDepositWorkflow,
  },
  // {
  //   id: "notification",
  //   title: "Notification",
  //   type: "item",
  //   icon: <Icon.Bell size={20} />,
  //   permissions: ["admin"],
  //   navLink: routes.notification,
  // },
  {
    id: "referAndEarn",
    title: "Refer And Earn",
    type: "item",
    icon: <Icon.Repeat size={20} />,
    permissions: ["admin", "sub-admin"],
    navLink: routes.referearn,
  },
];

if (country == "Canada") {
  let obj = [
    {
      id: "earnWalletHistory",
      title: "Earn Wallet History",
      type: "item",
      icon: <Icon.Watch size={20} />,
      permissions: ["admin"],
      navLink: routes.earnWalletHistory,
    },
    {
      id: "apayloPayment",
      title: "Apaylo",
      type: "collapse",
      icon: <Icon.DollarSign size={20} />,
      permissions: ["admin", "sub-admin"],
      children: [
        {
          id: "interacEmail",
          title: "Interac Email",
          type: "item",
          icon: <Icon.Mail size={20} />,
          permissions: ["admin", "sub-admin"],
          navLink: routes.apayloMail,
        },
        {
          id: "transaction",
          title: "Transaction",
          type: "item",
          icon: <Icon.Link size={20} />,
          permissions: ["admin", "sub-admin"],
          navLink: routes.apayloTransaction,
        },
        {
          id: "moreDetails",
          title: "More Details",
          type: "item",
          icon: <Icon.Archive size={20} />,
          permissions: ["admin", "sub-admin"],
          navLink: routes.moreDetails,
        },
      ],
    },
  ];
  navigationConfig.push(...obj);
}

if (country === "United Kingdom" || country === "Europe") {
  let obj = [
    {
      id: "token.io",
      title: "Token.io",
      type: "collapse",
      icon: <Icon.DollarSign size={20} />,
      parmission: ["admin", "sub-admin"],
      children: [
        {
          id: "transaction",
          title: "Transaction",
          type: "item",
          icon: <Icon.Link size={20} />,
          permissions: ["admin", "sub-admin"],
          navLink: routes.tokenioTransaction,
        },
      ],
    },
  ];
  navigationConfig.push(...obj);
}

// if (country === "United States") {
//   let bankItem = [
//     {
//       id: "bank",
//       title: "Add Bank",
//       type: "item",
//       icon: <Icon.Book size={20} />,
//       permissions: ["admin", "sub-admin"],
//       navLink: routes.addBank,
//     },
//   ];
//   navigationConfig.push(...bankItem);
// }

const { parse, stringify } = JSON;
const originalNavigationConfig = parse(stringify(navigationConfig));

export { originalNavigationConfig };
export default filterNavigationConfig(navigationConfig);
