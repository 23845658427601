import earnWalletTypes from "../../actionTypes/earnWalletTypes";

const initialState = {
  earnWalletsList: [],
  earnWalletDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case earnWalletTypes.SET_LIST: {
      return { ...state, earnWalletsList: action.payload };
    }

    case earnWalletTypes.DETAILS: {
      return { ...state, earnWalletDetails: action.payload };
    }

    case earnWalletTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case earnWalletTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case earnWalletTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
