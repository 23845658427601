import notificationTypes from "../../actionTypes/notificationTypes";

const initialState = {
 
  fetching: false,
  
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
   

    case notificationTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

  

    default:
      return { ...state };
  }
};
