import withdrawlBlockTypes from "../../actionTypes/withdrawlBlockTypes";

const initialState = {
  sum_txn_details: {},
  fetching: false,
};

export const sumTxnReducers = (state = initialState, action) => {
  switch (action.type) {
    case withdrawlBlockTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }
    case withdrawlBlockTypes.SET_TXN_SUM: {
      return { ...state, sum_txn_details: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};
