// import { combineReducers } from "redux"
// import { login } from "./loginReducer"

// const authReducers = combineReducers({
//   login
// })

// export default authReducers

import storageService from "../../../services/storageServices";
import authType from "../../actionTypes/authTypes";

const { name, role, email, country } = storageService.get() || {};

const initialState = {
  userRole: role || "",
  name: name || "",
  email: email || "",
  country: country || "",
  fetching: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case authType.CHANGE_ROLE: {
      return { ...state, userRole: action.payload };
    }

    case authType.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case authType.SET_USER: {
      return { ...state, ...action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
