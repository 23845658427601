import appConfigTypes from "../../actionTypes/appConfigTypes";

const initialState = {
  exchangeRateDetails: {},
  fetchingExchange: false,

  newsDetails: null,
  fetchingNews: false,

  interestDetails: null,
  fetchingInterest: false,

  referralDetails: {},
  fetchingReferral: false,

  contactDetails: null,
  fetchingContactDetails: false,

  socialDetails: null,
  fetchingSocial: false,

  withdrawDetails: null,
  fetchingWithdraw: false,

  withdrawHyptoDetails: null,
  fetchingHytopWithdraw: false,

  minLimitDetails: null,
  fetchingMinLimit: false,

  interacEmailDetails: null,
  fetchingInteracEmail: false,

  interacEmailManualDetails: null,
  fetchingInteracEmailManual: false,

  newUserExchangeRateDetails: {},
  fetchingNewUserExchange: false,

  earnWalletDetails: null,
  fetchingEarnWallet: false,

  minAmountDetails: null,
  fetchingMinAmount: false,
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case appConfigTypes.SET_EXCHANGE_DETAILS: {
      return { ...state, exchangeRateDetails: action.payload };
    }
    case appConfigTypes.EXCHANGE_FETCHING: {
      return { ...state, fetchingExchange: action.payload };
    }

    case appConfigTypes.SET_NEWS_DETAILS: {
      return { ...state, newsDetails: action.payload };
    }
    case appConfigTypes.NEWS_FETCHING: {
      return { ...state, fetchingNews: action.payload };
    }

    case appConfigTypes.SET_INTEREST_DETAILS: {
      return { ...state, interestDetails: action.payload };
    }
    case appConfigTypes.INTEREST_FETCHING: {
      return { ...state, fetchingInterest: action.payload };
    }

    case appConfigTypes.SET_REFERRAL_DETAILS: {
      return { ...state, referralDetails: action.payload };
    }
    case appConfigTypes.REFERRAL_FETCHING: {
      return { ...state, fetchingReferral: action.payload };
    }

    case appConfigTypes.SET_SOCIAL_DETAILS: {
      return { ...state, socialDetails: action.payload };
    }
    case appConfigTypes.SOCIAL_FETCHING: {
      return { ...state, fetchingSocial: action.payload };
    }

    case appConfigTypes.SET_CONTACT_DETAILS: {
      return { ...state, contactDetails: action.payload };
    }
    case appConfigTypes.CONTACT_FETCHING: {
      return { ...state, fetchingContactDetails: action.payload };
    }

    case appConfigTypes.SET_MIN_LIMIT_DETAILS: {
      return { ...state, minLimitDetails: action.payload };
    }
    case appConfigTypes.MIN_LIMIT_FETCHING: {
      return { ...state, fetchingMinLimit: action.payload };
    }

    case appConfigTypes.SET_WITHDRAW_DETAILS: {
      return { ...state, withdrawDetails: action.payload };
    }
    case appConfigTypes.WITHDRAW_FETCHING: {
      return { ...state, fetchingWithdraw: action.payload };
    }
    case appConfigTypes.SET_WITHDRAW_HYPTO_DETAILS: {
      return { ...state, withdrawHyptoDetails: action.payload };
    }

    case appConfigTypes.WITHDRAW_HYPTO_FETCHING: {
      return { ...state, fetchingHytopWithdraw: action.payload };
    }
    case appConfigTypes.SET_INTERAC_EMAIL: {
      return { ...state, interacEmailDetails: action.payload };
    }
    case appConfigTypes.INTERAC_EMAIL_FETCHING: {
      return { ...state, fetchingInteracEmail: action.payload };
    }

    case appConfigTypes.SET_INTERAC_EMAIL_MANUAL: {
      return { ...state, interacEmailManualDetails: action.payload };
    }
    case appConfigTypes.INTERAC_EMAIL_MANUAL_FETCHING: {
      return { ...state, fetchingInteracEmailManual: action.payload };
    }

    case appConfigTypes.SET_NEW_USER_EXCHANGE_RATE: {
      return { ...state, newUserExchangeRateDetails: action.payload };
    }
    case appConfigTypes.NEW_USER_EXCHANGE_RATE_FETCHING: {
      return { ...state, fetchingNewUserExchange: action.payload };
    }

    case appConfigTypes.SET_EARN_WALLET: {
      return { ...state, earnWalletDetails: action.payload };
    }
    case appConfigTypes.EARN_WALLET_FETCHING: {
      return { ...state, fetchingEarnWallet: action.payload };
    }

    case appConfigTypes.SET_MIN_AMOUNT: {
      return { ...state, minAmountDetails: action.payload };
    }
    case appConfigTypes.MIN_AMOUNT_FETCHING: {
      return { ...state, fetchingMinAmount: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
