import panCardsTypes from "../../actionTypes/panCardTypes";

const initialState = {
  panCardsList: [],
  panCardDetails: {},
  fetching: false,
  actionLoader: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case panCardsTypes.SET_LIST: {
      return { ...state, panCardsList: action.payload };
    }

    case panCardsTypes.DETAILS: {
      return { ...state, panCardDetails: action.payload };
    }

    case panCardsTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case panCardsTypes.ACTION_LOADER: {
      return { ...state, actionLoader: action.payload };
    }

    case panCardsTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default:
      return { ...state };
  }
};
