export const routes = {
  home: "/",
  login: "/pages/login",
  forgotPassword: "/pages/forgot-password",
  recoverPassword: "/pages/recover-password",
  settings: "/pages/settings",
  dashboard: "/pages/dashboard",
  volume: "/pages/volume",
  customers: "/pages/customers",
  customerAuditLog:"/pages/customerAuditLog",
  kyc: "/pages/kyc",
  pan: "/pages/pan",
  walletTopup: "/pages/wallet-top-up",
  transactionHistory: "/pages/transaction-history",
  withdrawal: "/pages/withdrawal",
  payee: "/pages/payee",
  transactionStatus: "/pages/transaction-status",
  ticket: "/pages/ticket",
  feedback: "/pages/feedback",
  banner: "/pages/manage-banner",
  urgentAnnouncement: "/pages/manage-announcement",
  appConfig: "/pages/manage-app-config",
  complyAdvantage: "/pages/comply-advantage",
  rejectReason: "/pages/reject-reason",
  apayloMail: "/pages/apayloMail",
  apayloTransaction: "/pages/apayloTransaction",
  moreDetails: "/pages/more-details",
  manageAdmin: "/pages/manage-admin",
  auditLogs: "/pages/audit-logs",
  manageDepositWorkflow: "/pages/add-money-options",
  earnWalletHistory: "/pages/earn-wallt-history",
  tokenioTransaction: "/pages/tokenioTransaction",
  referearn:"/pages/referandearn",
  nonTransaction:"/pages/nontransaction",
  walletbalance:"/pages/walletbalance",
  notification:"/pages/notification"
};
