import auditLogTypes from "../../actionTypes/auditLogsTypes";

// const dummyData = [
//   {
//     first_name: "Tribhuvan",
//     last_name: "Sharma",
//     ip_address: "192.0.0.0/29",
//     createdAt: "2021-12-09T06:08:15.761Z",
//     transaction_id: "943897213709",
//     module: "Withdrawal",
//     action: "Approved",
//   },
//   {
//     first_name: "Test",
//     last_name: "Sharma",
//     ip_address: "192.0.0.0/29",
//     createdAt: "2021-12-09T06:08:15.761Z",
//     transaction_id: "943897224809",
//     module: "Deposit",
//     action: "Approved",
//   },
//   {
//     first_name: "Dummy",
//     last_name: "Data",
//     ip_address: "192.0.0.0/29",
//     createdAt: "2021-12-09T06:08:15.761Z",
//     ppc_number: "PPC234",
//     module: "KYC",
//     action: "Rejected",
//   },
// ];

const initialState = {
  // auditLogsList: dummyData || [],
  auditLogsList: [],
  auditLogDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case auditLogTypes.SET_LIST: {
      return { ...state, auditLogsList: action.payload };
    }

    case auditLogTypes.DETAILS: {
      return { ...state, auditLogDetails: action.payload };
    }

    case auditLogTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case auditLogTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case auditLogTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
