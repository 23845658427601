import customersTypes from "../../actionTypes/customersTypes";

const initialState = {
  customersList: [],
  customerDetails: {},
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
  payeesList: [],
  payeePagination: { pageNo: 1, pages: 1 },
  userLedgersList: [],
  userLedgerPagination: { pageNo: 1, pages: 1 },
  actionFetching: false,
  auditLogList:[],
  paginationAuditLog:{pageNo:1,pages:1}
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case customersTypes.SET_LIST: {
      return { ...state, customersList: action.payload };
    }

    case customersTypes.DETAILS: {
      return { ...state, customerDetails: action.payload };
    }

    case customersTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case customersTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    case customersTypes.SET_PAYEE_LIST: {
      return { ...state, payeesList: action.payload };
    }

    case customersTypes.PAGINATION_PAYEE: {
      return { ...state, payeePagination: action.payload };
    }

    case customersTypes.SET_USER_LEDGER_LIST: {
      return { ...state, userLedgersList: action.payload };
    }

    case customersTypes.PAGINATION_USER_LEDGER: {
      return { ...state, userLedgerPagination: action.payload };
    }

    case customersTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case customersTypes.SET_AUDIT_LOG_LIST: {
      return { ...state, auditLogList: action.payload };
    }

    case customersTypes.PAGINATION_AUDIT_LOG: {
      return { ...state, paginationAuditLog: action.payload };
    }


    default: {
      return { ...state };
    }
  }
};
