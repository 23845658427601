import React, { useRef, useState } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";

import axios from "axios";
import * as Icon from "react-feather";
import { logOutAction } from "../../../redux/actions/auth/index";
import { connect } from "react-redux";
import CustomModal from "../../../components/custom/CustomModal";
import { history } from "../../../history";
import { routes } from "../../../component_route/Index";
import { useIdleTimer } from "react-idle-timer";
const UserDropdown = (props) => {
  const [open, setOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const idleTimer = useRef(null);

  const toggleOpen = () => {
    setOpen(!open);
  };

  const logOutUser = () => {
    localStorage.clear();
    props.logOutAction();
  };

  const onIdle = () => {
    setShowModal(true);
  };

  const IdleTimer = useIdleTimer({
    crossTab: true,
    ref: idleTimer,
    timeout: 1000 * 60 * 120,
    onIdle: onIdle,
  });

  return (
    <React.Fragment>
      <div idleTimer={IdleTimer}></div>
      <DropdownMenu right className="mr-0">
        <DropdownItem tag="span" onClick={() => history.push(routes.settings)}>
          <Icon.Settings size={14} className="mr-50" />
          <span className="align-middle">Settings</span>
        </DropdownItem>
        <DropdownItem divider />
        <DropdownItem tag="span" href="#" onClick={toggleOpen}>
          <Icon.Power size={14} className="mr-50 danger" />
          <span className="align-middle danger">Log Out</span>
        </DropdownItem>
      </DropdownMenu>
      <CustomModal
        className="logout_modal"
        open={showModal}
        showCancel={false}
        //toggleModal={()=>setShowModal(!showModal)}
        headingText={""}
        paragraphHeading={"Session expired !"}
        successAction={() => {
          setShowModal(false);
          logOutUser();
        }}
        successText="OK"
      />
      {open ? (
        <CustomModal
          className="logout_modal"
          open={open}
          toggleModal={toggleOpen}
          headingText={"Logout"}
          paragraphHeading={"Are you sure you want to logout ?"}
          successAction={logOutUser}
          successText="Log Out"
        />
      ) : null}
    </React.Fragment>
  );
};

class NavbarUser extends React.PureComponent {
  state = {
    navbarSearch: false,
    suggestions: [],
  };

  componentDidMount() {
    axios.get("/api/main-search/data").then(({ data }) => {
      this.setState({ suggestions: data.searchResult });
    });
  }

  handleNavbarSearch = () => {
    this.setState({
      navbarSearch: !this.state.navbarSearch,
    });
  };

  render() {
    return (
      <ul className="nav navbar-nav navbar-nav-user float-right">
        <UncontrolledDropdown tag="li" className="dropdown-user nav-item">
          <DropdownToggle tag="a" className="nav-link dropdown-user-link">
            <div className="user-nav d-sm-flex d-none">
              <span className="user-name text-bold-600">
                {this.props.userName}
              </span>
            </div>
            <span data-tour="user" style={{ color: "#00275E" }}>
              <Icon.MoreVertical />
            </span>
          </DropdownToggle>
          <UserDropdown {...this.props} />
        </UncontrolledDropdown>
      </ul>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logOutAction: (callback) => dispatch(logOutAction(callback)),
  };
};

export default connect(null, mapDispatchToProps)(NavbarUser);
