const tokenKey = "paypennyAdmin";
const { stringify, parse } = JSON;

const storageService = {
  get: () => parse(localStorage.getItem(tokenKey)),
  set: (value) => localStorage.setItem(tokenKey, stringify(value)),
  remove: () => localStorage.removeItem(tokenKey),
  clear: () => localStorage.clear(),
};

export default storageService;
