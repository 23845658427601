import feedbackTypes from "../../actionTypes/feedbacksTypes";

const initialState = {
  feedbacksList: [],
  feedbackDetails: {},
  fetching: false,
  actionFetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

export default (state = { ...initialState }, action) => {
  switch (action.type) {
    case feedbackTypes.SET_LIST: {
      return { ...state, feedbacksList: action.payload };
    }

    case feedbackTypes.DETAILS: {
      return { ...state, feedbackDetails: action.payload };
    }

    case feedbackTypes.FETCHING: {
      return { ...state, fetching: action.payload };
    }

    case feedbackTypes.ACTION_FETCHING: {
      return { ...state, actionFetching: action.payload };
    }

    case feedbackTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }

    default: {
      return { ...state };
    }
  }
};
