import storageService from "../services/storageServices";

const filterNavigationConfig = (config) => {
  const permissions = storageService.get()?.permission;

  if (!permissions) return;

  const permissionsMap = new Map();
  permissions.forEach((module) => {
    permissionsMap.set(module, {});
  });

  return config.filter((nav) => {
    const { modules, module } = nav;

    if (module) {
      return permissionsMap.has(module);
    } else if (modules) {
      let hasAny = false;

      modules.forEach((each) => {
        let subModule = null;
        let subModules = null;

        if (typeof each === "string") {
          subModule = each;
        } else if (Array.isArray(each)) {
          subModules = each;
        }

        if (permissionsMap.has(subModule)) {
          hasAny = true;
        }

        nav.children.forEach(({ subModule, subModules }) => {
          if (!subModule && !subModules) {
            hasAny = true;
          }

          if (permissionsMap.has(subModule)) {
            hasAny = true;
          }

          if (
            subModules
              ?.map((sub) => permissionsMap.has(sub))
              .filter((bool) => bool === true).length > 0
          ) {
            hasAny = true;
          }
        });
      });

      if (hasAny) {
        nav.children = nav.children.filter(({ subModule, subModules }) => {
          if (!subModule && !subModules) {
            return true;
          }

          if (permissionsMap.has(subModule)) {
            return true;
          }

          if (
            subModules
              ?.map((sub) => permissionsMap.has(sub))
              .filter((bool) => bool === true).length > 0
          ) {
            return true;
          }
        });
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  });
};

export default filterNavigationConfig;
