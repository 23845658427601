import { routes } from "../../../component_route/Index";
import { history } from "../../../history";
import {
  changePasswordApi,
  forgotPasswordApi,
  getProfileApi,
  loginApi,
  updateProfileApi,
} from "../../../services/authServices";
import storageService from "../../../services/storageServices";
import authType from "../../actionTypes/authTypes";
import { toast } from "react-toastify";
import permissionsObj from "../../../utills/adminPermissions";

export const loginAction = (data) => {
  return async (dispatch) => {
    dispatch({ type: authType.FETCHING, payload: true });

    const response = await loginApi(data);
    dispatch({ type: authType.FETCHING, payload: false });

    if (response?.statusCode == 200) {
      const { name, email, country, role, permission } = response?.data?.user;
      await storageService.set({
        ...response?.data?.user,
        permission: permission || [],
      });
      dispatch({ type: authType.CHANGE_ROLE, payload: role });
      dispatch({ type: authType.SET_USER, payload: { name, email, country } });
      window.location = `${routes.home}`;
    } else {
      if (response?.code == 400) {
        toast.error(response?.message);
      } else if (response?.status == 429) {
        toast.error(response?.error);
      }
    }
  };
};

export const logOutAction = (data) => {
  return async (dispatch) => {
    await localStorage.removeItem("userAdmin");
    history.push(routes.login);
  };
};

export const updateProfile = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await updateProfileApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    const profile = await getProfileApi();
    storageService.set({ ...storageService.get(), ...profile?.data?.result });

    dispatch({ type: authType.SET_USER, payload: profile.data?.result });
    toast.success(response?.message);
    callback();
  } else {
    toast.error(response?.message);
  }
};

export const changePassword = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await changePasswordApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    toast.success(
      "Password changes successfully. kindly login again to continue."
    );
    callback();
  } else {
    toast.error(response?.message);
  }
};

export const forgotPassword = (data, callback) => async (dispatch) => {
  dispatch({ type: authType.FETCHING, payload: true });

  const response = await forgotPasswordApi(data);

  dispatch({ type: authType.FETCHING, payload: false });
  if (response?.statusCode === 200) {
    toast.success("Please check your mail");
    callback();
  } else {
    toast.error(response?.message);
  }
};
