import depositLimitTypes from "../../actionTypes/depositLimitTypes";

const initialStates = {
  fetching: false,
  depositLimit: null,
  
};

const depositLimitReducer = (state = initialStates, action) => {
  switch (action.type) {
    case depositLimitTypes.fetchdepositLimit: {
      return { ...state, fetching: action.payload };
    }
    case depositLimitTypes.setDepositLimit: {
      return { ...state, depositLimit: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default depositLimitReducer