import walletbalanceTypes from "../../actionTypes/walletbalanceTypes";

const initialState = {
  walletbalance: [],
  fetching: false,
  pagination: { pageNo: 1, pages: 1 },
};

const walletbalanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case walletbalanceTypes.FETCHING_WALLET_BALANCE: {
      return { ...state, fetching: action.payload };
    }
    case walletbalanceTypes.SET_WALLETBALANCE: {
      return { ...state, walletbalance: action.payload };
    }
    case walletbalanceTypes.PAGINATION: {
      return { ...state, pagination: action.payload };
    }
    default: {
      return { ...state };
    }
  }
};

export default walletbalanceReducer;
