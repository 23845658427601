import { combineReducers } from "redux";
import announcement from "./announcement/index";
import appConfig from "./appConfig/index";
import auth from "./auth/index";
import banner from "./banners/index";
import customer from "./customers/index";
import customizer from "./customizer/";
import feedbacks from "./feedbacks/index";
import kyc from "./kyc/index";
import payee from "./payee/index";
import transaction from "./transaction/index";
import walletTopup from "./walletTopup/index";
import tickets from "./tickets/index";
import navbar from "./navbar/Index";
import comply from "./complyAdvantage/index";
import reason from "./reasons/index";
import apaylo from "./apaylo/index";
import admin from "./admin/index";
import manageDeposit from "./manageDeposit";
import auditLogs from "./auditLogs/index";
import earnWallet from "./earnWallet/index";
import panCard from "./panCard/index";
import referral from "./referral/index";
import walletbalance from "./walletbalance";
import depositLimit from "./depositLimit";
import { sumTxnReducers } from "./withdrawlBlock";
import notification from "./notify/index"
const rootReducer = combineReducers({
  customizer: customizer,
  auth,
  navbar: navbar,
  customer,
  banner,
  announcement,
  appConfig,
  feedbacks,
  tickets,
  kyc,
  payee,
  transaction,
  walletTopup,
  comply,
  reason,
  apaylo,
  admin,
  manageDeposit,
  auditLogs,
  earnWallet,
  panCard,
  referral,
  walletbalance,
  depositLimit,
  sumTxnReducers,
  notification
});

export default rootReducer;
