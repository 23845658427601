const permissionsObj = {
  dashboard: "dashboard",
  manageUsers: ["customers", "manage_kyc", "manage_payee"],
  manageTransaction: [
    ["withdrawal_management", "deposit_management", "wallet_management"],
    "edit_limit",
  ],
  manageContents: [
    "manage_banner",
    "announcement",
    "manage_app_config",
    "manage_ticket",
    "feedbacks",
    "reject_reason",
    "refer_earn",
  ],
  walleteBalanceUpdate: "wallete_balance_update",
  maximumDepositUpdate:"maximum_deposit",
  blockUnblockUser: "block_unblock_user",
  requestDocuments: "request_documents",
  kycUpdate: "kyc_update",
  withdrawlBlock:"withdrawl_block",
  managePayee: "manage_payee",
  withdrawalManagement: "withdrawal_management",
  depositManagement: "deposit_management",
  walletManagement: "wallet_management",
  editLimit: "edit_limit",
  manageBanner: "manage_banner",
  announcement: "announcement",
  manageAppConfig: "manage_app_config",
  manageTicket: "manage_ticket",
  feedbacks: "feedbacks",
  rejectReason: "reject_reason",
  referEarn:"refer_earn"
};

export default permissionsObj;
