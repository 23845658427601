import httpService from "./httpServices";

export const loginApi = (data) => {
  return httpService.post("/admin/login", data);
};

export const updateProfileApi = (data) => {
  return httpService.post("/admin/update-profile", data);
};

export const getProfileApi = () => {
  return httpService.get("/admin/profile");
};

export const forgotPasswordApi = (data) => {
  return httpService.post("/admin/forgot-password", data);
};

export const recoverPasswordApi = () => {};

export const changePasswordApi = (data) => {
  return httpService.post("/admin/change-password", data);
};
