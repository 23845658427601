const appConfigTypes = {
  EXCHANGE_FETCHING: "EXCHANGE_FETCHING",
  SET_EXCHANGE_DETAILS: "SET_EXCHANGE_DETAILS",

  NEWS_FETCHING: "NEWS_FETCHING",
  SET_NEWS_DETAILS: "SET_NEWS_DETAILS",

  INTEREST_FETCHING: "INTEREST_FETCHING",
  SET_INTEREST_DETAILS: "SET_INTEREST_DETAILS",

  REFERRAL_FETCHING: "REFERRAL_FETCHING",
  SET_REFERRAL_DETAILS: "SET_REFERRAL_DETAILS",

  CONTACT_FETCHING: "CONTACT_FETCHING",
  SET_CONTACT_DETAILS: "SET_CONTACT_DETAILS",

  SOCIAL_FETCHING: "SOCIAL_FETCHING",
  SET_SOCIAL_DETAILS: "SET_SOCIAL_DETAILS",

  MIN_LIMIT_FETCHING: "MIN_LIMIT_FETCHING",
  SET_MIN_LIMIT_DETAILS: "SET_MIN_LIMIT_DETAILS",

  WITHDRAW_FETCHING: "WITHDRAW_FETCHING",
  SET_WITHDRAW_DETAILS: "SET_WITHDRAW_DETAILS",

  WITHDRAW_HYPTO_FETCHING: "WITHDRAW_HYPTO_FETCHING",
  SET_WITHDRAW_HYPTO_DETAILS: "SET_WITHDRAW_HYPTO_DETAILS",

  INTERAC_EMAIL_FETCHING: "INTERAC_EMAIL_FETCHING",
  SET_INTERAC_EMAIL: "SET_INTERAC_EMAIL",

  INTERAC_EMAIL_MANUAL_FETCHING: "INTERAC_EMAIL_MANUAL_FETCHING",
  SET_INTERAC_EMAIL_MANUAL: "SET_INTERAC_EMAIL_MANUAL",

  NEW_USER_EXCHANGE_RATE_FETCHING: "NEW_USER_EXCHANGE_RATE_FETCHING",
  SET_NEW_USER_EXCHANGE_RATE: "SET_NEW_USER_EXCHANGE_RATE",

  NEW_USER_INFLATION_RATE_FETCHING: "NEW_USER_INFLATION_RATE_FETCHING",
  SET_NEW_USER_INFLATION_RATE: "SET_NEW_USER_INFLATION_RATE",

  EARN_WALLET_FETCHING: "EARN_WALLET_FETCHING",
  SET_EARN_WALLET: "SET_EARN_WALLET",

  MIN_AMOUNT_FETCHING: "MIN_AMOUNT_FETCHING",
  SET_MIN_AMOUNT: "SET_MIN_AMOUNT",
};

export default appConfigTypes;
