import React from "react";
import { connect } from "react-redux";
import logoCanada from "../../../assets/img/logo/logo.png";
import logoUK from "../../../assets/img/logo/logoUK.png";
import "../../../assets/scss/components/app-loader.scss";
class SpinnerComponent extends React.Component {
  constructor(props) {
    super(props);
  }
  render() {
    const { country } = this.props;
    const logo = country === "Canada" ? logoCanada : logoUK;
    return (
      <div className="fallback-spinner vh-100">
        <img className="fallback-logo" src={logo} alt="logo" />
        <div className="loading">
          <div className="effect-1 effects"></div>
          <div className="effect-2 effects"></div>
          <div className="effect-3 effects"></div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  const { country } = state.auth;
  return {
    country,
  };
}

export default connect(mapStateToProps)(SpinnerComponent);
